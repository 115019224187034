import { Fragment, useMemo } from 'react';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { Translation } from 'shared/components/translation/translation.component';
import { AnchorButton } from '@shared/components/elements/button.component';

import './footer.component.scss';
import { ChrFooterLogo } from 'utils/ChrFooterLogo';
import { ChrFooterLogoRight } from 'utils/ChrFooterLogoRight';

const tmcLeftLogoClass = "footer-image hidden-xs";
const tmcRightLogoClass = "footer-image hidden-lg hidden-md hidden-sm space-outer-right-md";


export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const user = useStoredUser();
  const useTMCLogo = useMemo(() => Boolean(user?.roles.TMCLogo), [user]);
  const { buildNumber } = appConstants;

  return (
      <footer id="bottom-footer" className="hidden-print">
        {useTMCLogo ?
          <>
            <img className={tmcLeftLogoClass} alt="TMC | A division of C.H. Robinson" src="/assets/images/TMCLogo.png" height="34" />
            <img className={tmcRightLogoClass} alt="TMC | A division of C.H. Robinson" src="/assets/images/TMC_logo_Dark-Gray_vert_RGB-2.png" height="40" />
          </>
          :
          <>
            <a href='https://www.chrobinson.com'><ChrFooterLogo className={tmcLeftLogoClass} alt="C.H. Robinson" /></a>
            <ChrFooterLogoRight className={tmcRightLogoClass} alt="C.H. Robinson" height='40' />
          </>
        }
        <div className="footer-links">
          {links.map((link, index, array) =>
            <Fragment key={link.resource + index}>
              {!link.isAnchor ? 
                <a key={link.href} href={link.href} className="footer-link" target="_blank">
                  <Translation resource={link.resource as keyof IResources} />
                  <span className="visually-hidden"><Translation resource="OPENS_IN_NEW_TAB" /></span>
                </a>
                : <AnchorButton key={link.href} id="help-faq-btn" href={link.href} track className="footer-link" target="_blank">
                    <Translation resource={link.resource as keyof IResources} />
                      <span className="visually-hidden"><Translation resource="OPENS_IN_NEW_TAB" /></span>
                  </AnchorButton>
                }
                {(index < array.length - 1) && <span style={{'paddingLeft':'8px'}}>|</span>}
            </Fragment>)
          }
        </div>
        <div className="footer-copyright help-text">
          <span style={{fontSize: 'x-small', marginRight: 5}}>v{buildNumber}</span>
          <span className="copyright">
          &copy;
            <Translation resource="2000_0_C_H_ROBINSON_WORLDWIDE_INC" replacements={[currentYear.toString()]} />
        </span>
          <span className="js-all-rights-reserved">
          <Translation resource="ALL_RIGHTS_RESERVED" />
        </span>
        </div>
      </footer>
  );
};

interface link {
  href: string;
  resource: string;
  isAnchor?: boolean;
}

const links = [
  {
    href: "https://www.chrobinson.com/en-us/privacy-notice/",
    resource: "GLOBAL_PRIVACY_NOTICE"
  } as link,
  {
    href: "https://www.chrobinson.com/your-privacy-rights",
    resource: "YOUR_PRIVACY_RIGHTS"
  } as link,
  {
    href: "https://www.chrobinson.com/en/us/Terms-Of-Use/",
    resource: "TERMS_OF_USE"
  } as link,
  {
    href: "https://www.chrobinson.com/en-us/carriers/carrier-support/",
    resource: "HELP_AND_FAQ",
    isAnchor: true
  } as link,
]
