export interface ICarrierIcon {
    className?: string;
    alt?: string;
    height?: string;
}

type ICarrierSvg = Partial<ICarrierIcon>;

export const ChrFooterLogoRight = ({ className, alt, height, ...rest }: ICarrierSvg) => {

    const style = "#0078ae"

    return (
        <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 34.955 40"
            style={{ enableBackground: "new 0 0 266.4 302.4" } as any}
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
            className={className}
            aria-label={alt}
            height={height}
        >
            <g id="g1">
                <path style={{ fill: style }} d="M133.2,18" id="path1" />
            </g>
            <g id="g7" transform="matrix(0.15015015,0,0,0.15015015,-2.6876877,-2.7027027)">
                <g id="g4">
                    <polygon
                        style={{ fill: style }}
                        points="138.9,126.8 138.9,67.1 163.7,81.4 169.3,71.6 133.2,50.7 97.1,71.6 102.7,81.4 127.5,67.1 127.5,126.8 133.2,130 "
                        id="polygon1" 
                    />
                    <polygon
                        style={{ fill: style }}
                        points="109.2,158.5 57.5,188.3 57.5,159.7 46.2,159.7 46.2,201.4 82.3,222.3 88,212.5 63.2,198.2 114.9,168.3 114.9,161.8 "
                        id="polygon2" 
                    />
                    <path
                        style={{ fill: style }}
                        d="M 133.2,18 17.9,84.6 v 133.2 l 115.3,66.6 115.3,-66.6 V 84.6 Z m 104,193.2 -104,60 -104,-60 v -120 l 104,-60 104,60 z"
                        id="path2" 
                    />
                    <polygon
                        style={{ fill: style }}
                        points="88,89.9 82.3,80.1 46.2,101 46.2,142.7 57.5,142.7 57.5,114.1 127.5,154.5 127.5,235.3 102.7,221 97.1,230.8 133.2,251.7 169.3,230.8 163.7,221 138.9,235.3 138.9,154.5 208.9,114.1 208.9,142.7 220.2,142.7 220.2,101 184.1,80.1 178.4,89.9 203.2,104.2 133.2,144.7 63.2,104.2 "
                        id="polygon3" 
                    />
                    <polygon
                        style={{ fill: style }}
                        points="151.5,168.3 203.2,198.2 178.4,212.5 184.1,222.3 220.2,201.4 220.2,159.7 208.9,159.7 208.9,188.3 157.2,158.5 151.5,161.8 "
                        id="polygon4" 
                    />
                </g>
                <g id="g6">
                    <g id="g5">
                        <path style={{ fill: style }} d="M 238.8,234.1 H 236 v 7.9 h -1.5 v -7.9 h -2.8 v -1.2 h 7.2 v 1.2 z m 11.8,7.9 h -1.5 v -7.6 0 l -3,7.6 h -0.9 l -3,-7.6 v 0 7.6 h -1.5 v -9.1 h 2.3 l 2.7,6.9 2.7,-6.9 h 2.3 v 9.1 z" id="path4" />
                    </g>
                </g>
            </g>
        </svg>
    )
}
