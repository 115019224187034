import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { LoadSummary } from 'shared/models/loads/load-summaries/load-summary.model';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { ResultSet } from 'shared/models/result-set.model';

export const SET_CURRENT_SEARCH_VALUES = 'SET_CURRENT_SEARCH_VALUES';
export const SET_PENDING_SEARCH_VALUES = 'SET_PENDING_SEARCH_VALUES';
export const RESET_CURRENT_SEARCH_VALUES = 'RESET_CURRENT_SEARCH_VALUES';
export const RESET_PENDING_SEARCH_VALUES = 'RESET_PENDING_SEARCH_VALUES';

export const setCurrentSearchValues = (values: MyLoadsSearchFormValues) => ({type: SET_CURRENT_SEARCH_VALUES, values});
export const setPendingSearchValues = (values: MyLoadsSearchFormValues) => ({type: SET_PENDING_SEARCH_VALUES, values});
export const resetCurrentSearchValues = () => ({type: RESET_CURRENT_SEARCH_VALUES});
export const resetPendingSearchValues = () => ({type: RESET_PENDING_SEARCH_VALUES});

export const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR';
export const RESET_SEARCH_ERROR = 'RESET_SEARCH_ERROR';

export const setSearchError = (error: string | JSX.Element) => ({type: SET_SEARCH_ERROR, error});
export const resetSearchError = () => ({type: RESET_SEARCH_ERROR});

export const SET_PAGINATION_PARAMS = 'SET_PAGINATION_PARAMS';
export const SET_SORT_PARAMS = 'SET_SORT_PARAMS';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';

export const setPaginationParams = (params: PaginationParams) => ({type: SET_PAGINATION_PARAMS, params});
export const setSortParams = (params: SortParams) => ({type: SET_SORT_PARAMS, params});
export const setSearchParams = (params: MyLoadsSearchRequest) => ({type: SET_SEARCH_PARAMS, params});

export const MY_LOADS_SEARCH = 'MY_LOADS_SEARCH';

export const MY_LOADS_SEARCH_START = `${REQUEST_START}:${MY_LOADS_SEARCH}`;
export const MY_LOADS_SEARCH_SUCCESS = `${REQUEST_SUCCESS}:${MY_LOADS_SEARCH}`;
export const MY_LOADS_SEARCH_FAILURE = `${REQUEST_FAILURE}:${MY_LOADS_SEARCH}`;

export const searchMyLoads = (criteria: MyLoadsSearchRequest) => ({type: MY_LOADS_SEARCH_START, criteria});
export const myLoadsSearchSuccess = (payload: ResultSet<LoadSummary>) => ({type: MY_LOADS_SEARCH_SUCCESS, payload});
export const myLoadsSearchFailure = (error: AjaxError) => ({type: MY_LOADS_SEARCH_FAILURE, error});
