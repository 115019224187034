import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';
import * as v from 'app/forms/control/form-validators';

export const EDIT_TRAILER_FORM = 'EDIT_TRAILER_FORM';

export const EDIT_TRAILER_FORM_STRUCTURE = {
  type: FormStructureType.Group,
  children: {
    trailerNumber: {
      type: FormStructureType.Field,
      validators: [v.required],
      initialValue: ''
    },
    tractorNumber: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    proNumber: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    driverFirstName: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    driverLastName: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    driverPhone: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    userID: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    groupID: {
      type: FormStructureType.Field,
      initialValue: ''
    },
    carrierCode: {
      type: FormStructureType.Field,
      initialValue: ''
    }
  }
};
