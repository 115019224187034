import moment from 'moment';
import { Moment } from 'moment';

import * as v from 'app/forms/control/form-validators';
import { LoadFilterOption } from 'shared/enums/load-filter-option.enum';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { FormGroupStructure, groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { API_DATE_FORMAT } from 'app/globals/constants';

const today: () => Moment = () => moment().startOf('day');
const yesterday: () => Moment = () => today().subtract(1, 'day');
const tomorrow: () => Moment = () => today().add(1, 'day');
const monthFromToday: () => Moment = () => today().add(1, 'month');

export class MyLoadsSearchForm extends NavCarrierFormGroup {

  constructor(structure: FormGroupStructure) {
    super(structure);
    this.get('intermodal.activity.start').addValidator(v.requiredWhen(() => this.get('loadFilter').value === '3'));
    this.validate();
  }

  isIntermodal() {
    return Number(this.get('loadFilter').value) === LoadFilterOption.IMDL;
  }

  getLoadFilterSelection(): number {
    return this.get('loadFilter').value;
  }

  private get loadFilterStatusParams(): LoadFilterStatusParams {
    const formValues = this.value;
    return {
      LoadFilter: formValues.loadFilter,
      Status: formValues.status
    };
  }

  private get intermodalParams(): IntermodalParams {
    const intermodal: IntermodalGroupValues = this.value.intermodal;
    if (parseInt(this.get('loadFilter').value, 10) !== 3) {
      return;
    }
    return {
      BookType: intermodal.bookType,
      ActivityStartDate: this.parseDate(intermodal.activity.start),
      ActivityEndDate: this.parseDate(intermodal.activity.end)
    };
  }

  private get dateRangeParams(): DateRangeParams {
    const dates: DateRangeGroupValues = this.value.dateRange;
    if (!this.isIntermodal()) {
      return {
        DropFromDate: this.parseDate(dates.drop.start),
        DropToDate: this.parseDate(dates.drop.end),
        PickupFromDate: this.parseDate(dates.pickup.start),
        PickupToDate: this.parseDate(dates.pickup.end)
      };
    }
  }

  private parseDate(date?: Moment): string {
    if (date) {
      date = moment(date);

      date.hours(0);
      date.minute(0);
      date.seconds(0);

      return date.format(API_DATE_FORMAT);
    }
  }

  private get locationParams(): LocationParams {
    const location: LocationGroupValues = this.value.location;
    const origin = location.origin;
    const destination = location.destination;
    return {
      OriginCountry: origin.country?.code,
      OriginState: origin.stateProv?.code,
      OriginCity: origin.city?.name,
      DestinationCountry: destination.country?.code,
      DestinationState: destination.stateProv?.code,
      DestinationCity: destination.city?.name
    };
  }

  private get referenceParams(): ReferenceParams | undefined {
    const reference: ReferenceGroupValues = this.value.reference;
    if (reference.searchNumberType == null || reference.searchNumberType === '') {
      // just don't add the field to the request.
      return;
    }
    const response = {};
    response[reference.searchType.code] = reference.searchNumberType;

    return response;
  }

  private get carrierParams(): CarrierParams {
    const carriers: CarriersGroupValues = this.value.carriers;
    return {
      CarrierCodes: [...carriers.carrierCodes, ''].join('|')
    };
  }

  public toSearchRequest(): MyLoadsBaseSearchRequest {
    const data: MyLoadsBaseSearchRequest = {
      ...this.dateRangeParams,
      ...this.locationParams,
      ...this.loadFilterStatusParams,
      ...this.referenceParams,
      ...this.intermodalParams,
      ...this.carrierParams
    };

    // strip all `null` or empty values from the query.
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === '|') && delete data[key]);

    return data;
  }
}

export const MY_LOADS_SEARCH_FORM_KEY = 'MY_LOADS_SEARCH_FORM';

export const createMyLoadsSearchFormStructure = (): FormGroupStructure => groupStructure({
  dateRange: groupStructure({
    pickup: groupStructure(
      {
        start: fieldStructure(yesterday()),
        end: fieldStructure(monthFromToday())
      },
      [v.validateDateRangeOrder, v.validateDateRangeComplete]
    ),
    drop: groupStructure(
      {
        start: fieldStructure(null),
        end: fieldStructure(null)
      },
      [v.validateDateRangeOrder, v.validateDateRangeComplete]
    )
  }),
  intermodal: groupStructure({
    bookType: fieldStructure('All'),
    activity: groupStructure({
        start: fieldStructure(yesterday()),
        end: fieldStructure(tomorrow())
      },
      [v.validateDateRangeOrder, v.validateDateRangeComplete])
  }),
  loadFilter: fieldStructure(0),
  status: fieldStructure(0),
  reference: groupStructure({
    searchType: fieldStructure({
      code: 'LoadNumber',
      description: 'LOAD_NUMBER'
    }),
    searchNumberType: fieldStructure()
  }),
  location: groupStructure({
    origin: groupStructure({
      country: fieldStructure(),
      stateProv: fieldStructure(),
      city: fieldStructure()
    }),
    destination: groupStructure({
      country: fieldStructure(),
      stateProv: fieldStructure(),
      city: fieldStructure()
    })
  }),
  carriers: groupStructure({
    saveSearch: fieldStructure(),
    selectAll: fieldStructure(true),
    carrierCodes: fieldStructure([])
  })
});
